<template>
  <header :class="{ 'show-filters': showFilters }">
    <nav v-if="showFilters" class="filters">
      <a href="#" class="active">Tous</a>
      <a href="#">Actif</a>
    </nav>
    <section class="search">
      <FormSearch
        title="forms.label.search"
        placeholder="Rechercher un produit par nom"
        v-model="searchInput"
        @input="debouncedSearch"
      />
    </section>
  </header>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import FormSearch from '../Shared/Forms/FormSearch.vue';
import { useProductStore } from '../../stores/products.js';

defineProps({
  showFilters: {
    type: Boolean,
    default: true
  }
});

const productStore = useProductStore();
const route = useRoute();

const searchInput = ref('');
let debounceTimer;

const debouncedSearch = () => {
  clearTimeout(debounceTimer);
  debounceTimer = setTimeout(() => {
    if (searchInput.value.length >= 3 || searchInput.value === '') {
      productStore.setSearchQuery(searchInput.value);
    }
  }, 300);
};

const resetSearch = () => {
  searchInput.value = '';
  productStore.setSearchQuery('');
};

watch(
  () => route.name,
  (newRouteName, oldRouteName) => {
    if (newRouteName !== oldRouteName) {
      resetSearch();
    }
  }
);

watch(
  () => route.query.search,
  (newSearch) => {
    if (newSearch !== searchInput.value) {
      searchInput.value = newSearch || '';
      productStore.setSearchQuery(newSearch || '');
    }
  },
  { immediate: true }
);
</script>

<style scoped>
nav.filters {
  display: flex;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid var(--gainsboro);
}
nav.filters a {
  text-decoration: none;
  font-size: 90%;
  color: #444444;
  padding: 12px 8px;
  margin-bottom: -1px;
  border-bottom: 3px solid transparent;
}
nav.filters a.active {
  border-bottom: 3px solid var(--brand-blue);
}
nav.filters a:hover {
  border-bottom: 3px solid var(--gainsboro);
}
section.search {
  display: flex;
  gap: 20px;
}
.show-filters section.search {
  margin: 20px 0;
}
</style>
